.carousel.carousel-slider .control-prev.control-arrow::before {
  border-right: 8px solid #000;
}

.carousel.carousel-slider .control-next.control-arrow::before {
  border-left: 8px solid #000;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none;
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
  overflow: hidden;
}
